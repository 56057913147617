import PhoneLib, { PhoneNumberType } from 'google-libphonenumber'

export const getFormattedDate  = () => {
  //  TW want the date used in the orderId/PlainPaymentRef to be in this format DDMMYYYYHHMM
  const currentDate = new Date();

  // adds a zero to the start of singleDigitNumbers 
  const addLeadingZero = (dateElement: number) => ("0" + dateElement).slice(-2);
  
  // current day
  const day = addLeadingZero(currentDate.getDate());
  
  // gets current month and adds 1 as the default months go from 0-11
  const month = addLeadingZero(currentDate.getMonth() + 1);
  
  // current year
  const year = currentDate.getFullYear();
  
  // current hours
  const hours = addLeadingZero(currentDate.getHours()) ;
  
  // current minutes
  const minutes =  addLeadingZero(currentDate.getMinutes());

  const finalDate = day + month + year + hours + minutes 

  return finalDate           
}


const phoneUtil = PhoneLib.PhoneNumberUtil.getInstance()

export const isValidUkMobileNumber = (phoneNumber: string): boolean => {

  if(!phoneNumber) return false

  try {

    const phoneNumberObject = phoneUtil.parse(phoneNumber, 'GB')
    const e164Number = phoneUtil.format(phoneNumberObject, PhoneLib.PhoneNumberFormat.E164)
    const isValidUKNumber = phoneUtil.isValidNumberForRegion(phoneUtil.parse(e164Number, 'GB'), 'GB');
    const isMobileNumber = phoneUtil.getNumberType(phoneNumberObject) === PhoneNumberType.MOBILE
    
    return (isValidUKNumber && isMobileNumber) ? true : false

  } catch(error) {
    console.log(`Error trying to validate the customer PhoneNumber ${phoneNumber} :  ${error}`)
    return false
  }
}

export const formatToE164Number = (phoneNumber: string): string => {

  if(!phoneNumber) return ''

  try {

    const phoneNumberObject = phoneUtil.parse(phoneNumber, 'GB')
    const e164Number = phoneUtil.format(phoneNumberObject, PhoneLib.PhoneNumberFormat.E164)
    
    return e164Number
    
  } catch(error) {

    console.log(`Error converting the PhoneNumber ${phoneNumber} to E164 format: ${error}`)
    return phoneNumber

  }
}